import React from "react"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import { Box, Typography } from "@material-ui/core"
import Layout from "../components/layout"
import { graphql } from 'gatsby';



export default function NotFoundPage() {
  const {t} = useTranslation();
  return (
    <Layout>
      404
    </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`